
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { AppHostnameMixin, BAIconsMixin } from '@/mixins';

@Component
export default class ShareableProfileInfo extends Mixins(AppHostnameMixin, BAIconsMixin) {
	@Prop() value: boolean;
	@Prop({ type: String, default: undefined }) label: string;
	@Prop({ type: String, default: undefined }) icon: string;
	@Prop({ type: String, required: true }) data: string;
	@Prop({ type: Boolean, default: true }) dataCheck;
	@Prop({ type: Boolean }) validate;

	get ValidData(): boolean {
		if( !this.validate ) return true;
		return ( this.data && this.data != null && this.data != undefined && this.data != '' && this.dataCheck );
	}

	onClickCheckbox() {
		this.value = true;
		this.$emit('update:profile', this.value);
	}
}
