
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric, AthleteMetricValues } from '@/../types/enums';
import { VuetifyMixin, AppHostnameMixin, PhysicalAssessmentMixin, AthleteRoutingMixin, StringsMixin, HelpInfoMixin, AthleteApiMixin } from '@/mixins';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { AthleteAssessmentDataModel } from '@/models/athlete/AthleteAssessmentDataModel';

import AthleteStatCard from './AthleteStatCard.vue';

@Component({
	components:{
		AthleteStatCard,
	}
})
export default class AthleteVerifiedAssessmentsCard extends Mixins(VuetifyMixin, AppHostnameMixin, PhysicalAssessmentMixin, AthleteRoutingMixin, StringsMixin, HelpInfoMixin, AthleteApiMixin) {
	@Prop({ type: Boolean }) flat;
	@Prop({ required: true }) athlete: AthleteProfileModel;
	@Prop({ required: true }) assessmentData: AthleteAssessmentDataModel;
	@Prop({ type: Boolean }) buttons;
	@Prop({ type: Boolean }) extended;

	get HasAssessment(): boolean{
		return this.assessmentData !== null && this.assessmentData !== undefined;
	}
	
	compareData(): void{
		this.expandMetric(AthleteMetric.Acceleration);
	}

	expandMetric(metric: AthleteMetric): void{
		if( this.AthleteAppActive ) this.$emit('expandMetric', metric);
	}
	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get AssessmentData(): AthleteAssessmentDataModel | null{
		return this.assessmentData;
	}

	get LastAssessedDate(): string {
		if(!this.assessmentData) return undefined;
		return this.formatDatePretty(this.assessmentData.assessmentDate);
	}
}
