var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AssessmentDataInfoProvider',{style:(_vm.RootStyle),attrs:{"metric":_vm.metric,"assessmentData":_vm.selfAssessment,"loadData":_vm.loadData},scopedSlots:_vm._u([{key:"default",fn:function({
		athleteId,
		TestDate,
		Title,
		IconComponentName,
		MetricData,
	}){return [(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"card"}}):_c('ExpandingCard',_vm._b({attrs:{"height":_vm.height,"color":"baColorVibrantBlue"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-column flex-grow-1 upper-case ba-metric-title baColorVibrantBlue--text"},[_vm._v(" "+_vm._s(Title)+" ")])]},proxy:true},{key:"subtitle",fn:function(){return [_c('div',{staticClass:"ba-metric-subtitle"},[_vm._v(" "+_vm._s(MetricData.label)+" ")])]},proxy:true},{key:"icon",fn:function(){return [_c('div',[_c(IconComponentName,{tag:"component",attrs:{"size":"20","color":"baColorVibrantBlue"}})],1)]},proxy:true}])},'ExpandingCard',_vm.$attrs,false),[_c('div',[_c('span',{staticClass:"ba-metric-value baColorVibrantBlue--text"},[_vm._v(_vm._s(MetricData.value))]),_c('span',{staticClass:"ba-metric-unit baColorVibrantBlue--text pl-2"},[_vm._v(_vm._s(MetricData.unit))])]),[_vm._t("append-content")]],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }