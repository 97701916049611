
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, AppHostnameMixin, MindsetMixin, MindsetReportMixin, AthleteRoutingMixin, HelpInfoMixin } from "@/mixins";

import RyzerAthleteTypeCard from '@/components/mindset/RyzerAthleteTypeCard.vue';
import RyzerScore from "@/components/ui/RyzerScore.vue";
import MindsetInformation from '@/components/mindset/MindsetInformation.vue'
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";

@Component({
	components: {
		RyzerScore,
		RyzerAthleteTypeCard,
		MindsetInformation,
		SubscriptionInfoProvider
	}
})
export default class AthleteMindsetReportCard extends Mixins(VuetifyMixin, AppHostnameMixin, MindsetMixin, MindsetReportMixin, AthleteRoutingMixin, HelpInfoMixin) {
	@Prop({ type: Boolean }) buttons;
	@Prop({ type: Boolean }) extended;
	@Prop({ type: Boolean }) flat;

	get MindsetAssessmentEligible(): boolean {
		return !this.HasMindsetReport && this.AthleteAppActive;
	}
}
