
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AthleteMetric, PerformanceCategory } from '@/../types/enums';
import { VuetifyMixin, AppHostnameMixin } from '@/mixins';
import ProgressBar from '@/components/charts/ProgressBar.vue';
import ExpandingCard from '@/components/ui/ExpandingCard.vue';
import MetricChart from './MetricChart.vue';
import PerformanceLevelPreview from './PerformanceLevelPreview.vue';
import { AthleteAssessmentDataModel } from '@/models';
import { title, performanceCategoryToColor, getMetricIcon, assessmentToMetric } from '@/pipes';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { AgeGroup } from '@best-athletes/ba-types';
import { MetricData, Comparison } from '@/../types/interfaces';
import { statisticApi } from '@/api/StatisticApi';

import RulerSvg from '@/components/svg/RulerSvg.vue';
import SpeedometerSvg from '@/components/svg/SpeedometerSvg.vue';
import StopwatchSvg from '@/components/svg/StopwatchSvg.vue';
import LightningSvg from '@/components/svg/LightningSvg.vue';
import AgilitySvg from '@/components/svg/AgilitySvg.vue';
import HeartVitalSvg from '@/components/svg/HeartVitalSvg.vue';


@Component({
	components: {
		ExpandingCard,
		MetricChart,
		ProgressBar,
		PerformanceLevelPreview,

		RulerSvg,
		SpeedometerSvg,
		StopwatchSvg,
		LightningSvg,
		AgilitySvg,
		HeartVitalSvg,
	}
})
export default class AthleteStatCard extends Mixins(VuetifyMixin, AppHostnameMixin){
	performanceCategoryToColor = performanceCategoryToColor;
	@Prop({ required: true }) metric: AthleteMetric;
	@Prop({ required: true }) athlete: AthleteProfileModel;
	@Prop({ required: true }) assessmentData: AthleteAssessmentDataModel;
	@Prop({ type: Boolean, default: false }) hover: boolean;
	@Prop({ type: Boolean, default: false }) isExpanded: boolean;
	@Prop({ type: Number, default: '100%' }) height: string;

	loading = true;
	comparison: Comparison = undefined;
	mounted() {
		this.loadComparison(this.assessmentData.id);
	}
	get IsLoading(): boolean {
		return this.loading;
	}
	async loadComparison(assessmentId: string): Promise<Comparison> {
		// public access does not load data. only active accounts can see this data
		if( this.public ) {
			this.comparison = {
				ageGroup: AgeGroup.U17,
				speed: PerformanceCategory.Competitive,
				acceleration: PerformanceCategory.HighPerformance,
				agility: PerformanceCategory.ProAcademy,
				power: PerformanceCategory.International,
				recovery: PerformanceCategory.ProAcademy,
			};
			this.loading = false;
			return this.comparison;
		};

		this.loading = true;
		const comparisons = await statisticApi.compare({assessmentId});
		if( comparisons && comparisons.length > 0 ) {
			this.comparison = comparisons[comparisons.length - 1];
		}
		this.loading = false;
		return this.comparison;
	}

	updateIsExpanded(value: boolean): void{
		this.isExpanded = value;
		this.$emit('update:is-expanded', value);
	}

	get Over18(): boolean{
		switch( this.assessmentData.AgeGroup ) {
			case AgeGroup.U18:
			case AgeGroup.U19:
			case AgeGroup.U20:
			case AgeGroup.O20:
				return true;
		}
		return false;
	}

	get PerformanceLevel(): PerformanceCategory {
		if( this.MetricData.missing ) return undefined;
		if( !this.comparison ) return undefined
		return this.comparison[this.metric];
	}
	get MetricData(): MetricData {
		switch( this.metric ) {
			case AthleteMetric.Acceleration:
			case AthleteMetric.Speed:
			case AthleteMetric.Agility:
			return assessmentToMetric(this.assessmentData, this.metric, true);
		}
		return assessmentToMetric(this.assessmentData, this.metric);
	}
	get HasMetricData(): boolean {
		if( !this.MetricData ) return false;
		return !this.MetricData.missing;
	}
	get Title(): string{
		return title(this.metric);
	}
	get IconComponentName(): string{
		return getMetricIcon(this.metric);
	}
	get Cursor(): string {
		if( this.AthleteAppActive ) return "cursor-pointer";
		return "";
	}
	get RootStyle(): Record<string,any>{
		return {
			'height': this.height,
			'flex-basis': '300px',
		};
	}
}
