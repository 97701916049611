
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin, PhysicalAssessmentMixin, AthleteRoutingMixin, HelpInfoMixin, StringsMixin } from '@/mixins';

import { AthleteMetric, AthleteMetricValues } from '@/../types/enums';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { SelfAssessmentModel } from '@/models/athlete/SelfAssessmentModel';
import AthleteSelfAssessmentStatCard from './AthleteSelfAssessmentStatCard.vue';

@Component({
	components:{
		AthleteSelfAssessmentStatCard,
	}
})
export default class AthleteSelfAssessmentsCard extends Mixins(VuetifyMixin, BAIconsMixin, PhysicalAssessmentMixin, AthleteRoutingMixin, HelpInfoMixin, StringsMixin){
	@Prop({ default: null }) athlete: AthleteProfileModel | null;
	@Prop({ default: null }) selfAssessment: SelfAssessmentModel | null;
	@Prop({ type: Boolean }) flat;
	@Prop({ type: Boolean }) buttons;
	@Prop({ type: Boolean }) extended;
	@Prop({ type: Boolean }) loadData;

	get AthleteMetrics(): AthleteMetric[]{
		return AthleteMetricValues;
	}
	get SelfAssessmentData(): SelfAssessmentModel | null{
		return this.selfAssessment;
	}
	get HasSelfAssessment(): boolean{
		return this.selfAssessment !== null && this.selfAssessment !== undefined && this.selfAssessment.IsStarted;
	}

	get AthleteProfile(): AthleteProfileModel{
		return this.athlete;
	}

	get LastAssessedDate(): string | undefined{
		if(!this.selfAssessment) return undefined;
		return this.formatDatePretty(this.selfAssessment.startDate);
	}
}
