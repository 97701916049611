
import { VuetifyMixin, BAIconsMixin, AppHostnameMixin, StringsMixin, AthleteRoutingMixin, HelpInfoMixin, AthleteProfileMixin, AthleteApiMixin } from '@/mixins';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import AthleteScoutingReportsInfoProvider from "@/components/hoc/AthleteScoutingReportsInfoProvider.vue";
import { DataTableHeader } from 'vuetify';
import { ScoutingReportModel } from '@/models/scoutingReport/ScoutingReportModel';
import { AuthMixin, ScoutingReportMixin } from '@/mixins';
import { ViewScoutingReport } from "@/../types/constants/web_client_user.routes";
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";
import ScoutingScoreSummary from "@/components/scoutingReport/ScoutingScoreSummary.vue"
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import ScoutingReportDetailProvider from "@/components/hoc/ScoutingReportDetailProvider.vue";
import RadarChartJS from "@/components/charts/RadarChartJS.vue";
import ScoutingReportSpiderChart from "@/components/scoutingReport/ScoutingReportSpiderChart.vue";
import { athleteApi } from '@/api/AthleteApi';

@Component({
	components: {
		AthleteScoutingReportsInfoProvider,
		ScoutingReportScoreChip,
		ScoutingReportSpiderChart,
		ScoutingScoreSummary,
		RecruitingProfileInfo,
		ScoutingReportDetailProvider,
		RadarChartJS
	}
})
export default class AthleteScoutingReportsCard extends Mixins(AuthMixin, AppHostnameMixin, BAIconsMixin, StringsMixin, VuetifyMixin, AthleteProfileMixin, ScoutingReportMixin, AthleteRoutingMixin, HelpInfoMixin, AthleteApiMixin){
	@Prop() scoutingReport: ScoutingReportModel;
	@Prop({ type: Boolean }) flat;
	@Prop({ type: Boolean }) buttons;
	@Prop({ type: Boolean }) history;
	@Prop({ type: Boolean }) extended;

	searchScoutingReports = ""
	expanded: []
	scoutingReportHeaders: Array<DataTableHeader> = [
		{ text: 'Date', value: 'date', align: 'center', sortable: true},
		{ text: 'Location', value: 'location', filterable: true, sortable: false},
		{ text: 'Performance', value: 'performance', align: 'center', sortable: true},
		{ text: 'Potential', value: 'potential', align: 'center', sortable: true},
		{ text: '4 Corners', value: 'corners.score', align: 'center', sortable: false},
		{ text: '6 Moments', value: 'moments.score', align: 'center', sortable: false},
		{ text: 'C.R.I.S.P', value: 'crisp.score', align: 'center', sortable: false},
		{ text: '', value: 'actions', align: 'center', sortable: false},
		{ text: '', value: 'data-table-expand', sortable: false},
	]

	
	updatingFeatured: boolean = false;
	HasScoutingReports(scoutingReports: ScoutingReportModel[]): boolean {
		if( this.IsNotEmpty(this.scoutingReport) ) return true;
		if( this.IsEmptyArray(scoutingReports) ) return false;
		return( scoutingReports.length > 0 );
	}

	FeaturedScoutingReport(scoutingReports: ScoutingReportModel[]): ScoutingReportModel {
		if( this.IsNotEmpty(this.scoutingReport) ) return this.scoutingReport;
		if( !this.HasScoutingReports(scoutingReports) ) return undefined;
		this.scoutingReport = scoutingReports.find(sr => sr.id === this.athleteProfile.featuredScoutingReport);
		if( this.IsNotEmpty(this.scoutingReport) ) return this.scoutingReport;
		return this.LatestScoutingReport(scoutingReports);
	}
	LatestScoutingReport(scoutingReports: ScoutingReportModel[]): ScoutingReportModel {
		if( this.IsNotEmpty(this.scoutingReport) ) return this.scoutingReport;
		if( !this.HasScoutingReports(scoutingReports) ) return undefined;
		this.scoutingReport = scoutingReports.slice(-1)[0];
		return this.scoutingReport;
	}
	FeaturedScoutingReportDate(scoutingReports: ScoutingReportModel[]): string {
		const latestScoutingReport = this.FeaturedScoutingReport(scoutingReports);
		if( !latestScoutingReport ) return 'No Scouting Reports available';
		return this.formatDatePretty(latestScoutingReport.createdAt);		
	}
	
	goToScoutingReportDetailPage(reportId: string) {
		if( this.CoachAppActive || this.AdminAppActive ) {
			this.$router.push({
				name: ViewScoutingReport,
				params:{
					reportId,
				},
			}) 
		} else {
			this.goToScoutingReport(reportId);
		}
	}

	isFeatured(report: ScoutingReportModel): boolean {
		return( this.athleteProfile.featuredScoutingReport === report.id );
	}
	async onSetFeatured(report: ScoutingReportModel) {
        this.updatingFeatured = true;
        this.athleteProfile.featuredScoutingReport = report.id;
        await athleteApi.save(this.athleteProfile);
        this.updatingFeatured = false;
	}
}
