
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class ExpandingCard extends Mixins(VuetifyMixin){

	@Prop() icon: string;
	@Prop({ default: 'baColorVibrantBlue' }) private color: string;
	@Prop({ default: 'baColorDeepBlue' }) private secondaryColor: string;
	@Prop({ default: false, type: Boolean }) headerDark: boolean;
	@Prop({ default: true, type: Boolean }) outlined: boolean;
	@Prop({ default: false, type: Boolean }) bodyDark: boolean;
	@Prop({ default: null }) private minWidth: string | null;

	get MinWidth(): string{
		return `${this.minWidth}px`;
	}
	get CardStyle(): Record<string, any>{
		return {
			'min-width': this.minWidth === null ? undefined : this.MinWidth,
		}
	}

	clickedExpand(): void{
		if(this.isExpanded){
			this.$emit('click:less');
		}else{
			this.$emit('click:more');
		}
	}

	get LargeScreen(): boolean{
		return this.$vuetify.breakpoint.lgAndUp;
	}

	get SecondaryColor(): string{
		return this.getColor(this.secondaryColor);
	}
	get PrimaryColor(): string{
		return this.getColor(this.color);
	}

	get ExpandIconColor(): string{
		if(this.bodyDark){
			return 'white';
		}else{
			return 'baColorVibrantBlue';
		}
	}
	@Prop({ type: Boolean, default: false }) isExpanded: boolean;
	get ExpandText(): string{
		if(this.isExpanded){
			return 'Less';
		}else{
			return 'More';
		}
	}
	get HasExpansionContent(): boolean{
		return this.$scopedSlots['expanded'] !== undefined;
	}
	get HasExpansionXContent(): boolean{
		return this.$scopedSlots['expanded-x'] !== undefined && this.isExpanded;
	}

}
