
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, StringsMixin, BAIconsMixin, FormRulesMixin, AthleteApiMixin, AthleteRoutingMixin } from "@/mixins";
import { DataTableHeader } from 'vuetify';
import { ScheduleDetails } from "types";
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";

@Component({
	components: {
        TimePickerDialog,
	}
})
export default class AthleteScheduleTable extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, FormRulesMixin, AthleteApiMixin, AthleteRoutingMixin) {
    @Prop({ required: true }) schedule: ScheduleDetails[];
	@Prop({ type: Boolean }) showEdit;
    @Prop({ type: Boolean }) disableEdit;
    @Prop({ type: Boolean }) hideExpandedView;
    @Prop({ type: Boolean }) dim;

	get TableHeaders(): DataTableHeader<any>[] {
		const headers : DataTableHeader<any>[] = [
			{ text: 'Date', value: 'date', sortable: true, width:100 },
			{ text: 'Event', value: 'name', sortable: false, width:100 },
            { text: 'Location', value: 'location', sortable: false, width:150 },
		];

        if( this.showEdit ) headers.push( { text: '', value: 'actions', sortable: false, width:25 } );
        if( !this.hideExpandedView ) headers.push( { text: '', value: 'data-table-expand', width:25 } );

        return headers;
	}
    get TotalItems(): number {
        if( this.IsEmptyArray( this.schedule ) ) return 0;
        return this.schedule.length;
    }

    onEditScheduleItem(item: ScheduleDetails) {
        this.$emit('edit', item);
    }

    onToggleArchive(item: ScheduleDetails) {
        item.archive = !item.archive;
        this.$emit('update');
    }

    onDeleteScheduleItem(item: ScheduleDetails) {
        this.$emit('delete', item);
    }

    GoogleMap(item: ScheduleDetails): string {
        var mapUrl = `https://maps.google.com/?q=`;
        if( this.IsNotEmpty(item.location) ) mapUrl += item.location;
        return mapUrl;
    }
}

