
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { athleteApi } from '@/api/AthleteApi';
import { AppHostnameMixin, MyCoachMixin } from '@/mixins';
import { RecruitingProfileModel } from '@/models/athlete/RecruitingProfileModel';
import { errorIs403, errorIs401 } from '@/helpers/error-handler';
import { coachRecruitingSharingStore } from '@/store'
import { TeamModel } from '@/models/team';

@Component({
	render(h){
		return h(
			this.as,
			this.$slots.default ? this.$slots.default : this.$scopedSlots.default(this.SlotProps)
		);
	},
})
export default class RecruitingProfileInfoProvider extends Mixins(AppHostnameMixin, MyCoachMixin){
	@Prop({ type: String, required: true }) athleteId;
	@Prop({ default: 'div' }) as: string;
	@Prop({ type: String }) sharingUrlId;

	ready = false;
	error = false;
	athleteTeams: Array<TeamModel> = [];
	athleteAssessmentData = null;
	selfAssessmentSummary = null;
	scoutingAverage = null;
	scoutingReport = null;
	mindsetReport = null;
	athleteProfile = null;
	errorMsg: string = "ERROR";

	async mounted(): Promise<void> {
		await this.initProfile();
	}

	async loadRecruitingProfile(): Promise<RecruitingProfileModel> {
		this.error = false;
		try{
			if ( this.sharingUrlId ) {
				return await athleteApi.getSharingProfile(this.athleteId, this.sharingUrlId)
			}
			else {
				return await athleteApi.getAthleteRecruitingProfile(this.athleteId, {as : this.getAppType} );
			}
		}catch(e){
			if(errorIs401(e) || errorIs403(e)){
				const profile = await coachRecruitingSharingStore.getRecruitingProfileIfShareExists({
					athleteId: this.athleteId,
					coachId: this.MyCoachId
				});
				if(profile !== null) return profile;
			}
			throw e;
		}
	}
	async initProfile():Promise<void> {
		try{
			this.ready = false;
			const recruitingProfile = await this.loadRecruitingProfile();
			// Set the athlete profile and teams
			this.athleteTeams = recruitingProfile.teams;
			this.athleteAssessmentData = recruitingProfile.physicalPerformance;
			this.selfAssessmentSummary = recruitingProfile.selfAssessment;
			this.scoutingAverage = recruitingProfile.scoutingAverage;
			this.scoutingReport = recruitingProfile.scoutingReport;
			this.mindsetReport = recruitingProfile.mindsetReport;	
			this.athleteProfile = recruitingProfile.athleteProfile;
		} catch(e) {
			this.errorMsg += `: ${e}`;
			this.error = true;
		}
		this.ready = true;
		
	}
	get SlotProps() {
		return {
			athleteTeams: this.athleteTeams,
			athleteAssessmentData: this.athleteAssessmentData,
			selfAssessmentSummary: this.selfAssessmentSummary,
			scoutingAverage: this.scoutingAverage,
			scoutingReport: this.scoutingReport,
			mindsetReport: this.mindsetReport,
			athleteProfile: this.athleteProfile,
			Loading: this.Loading,
			Ready: this.Ready,
			Error: this.error,
			errorMsg: this.errorMsg
		}
	}
	get Loading(): boolean {
		return !this.ready;
	}
	get Ready(): boolean {
		return this.ready;
	}
}
